<template>
  <moe-page title="新建秒杀活动">
    <moe-form
      class="default-form"
      ref="flashForm"
      :showBack="false"
      :showClose="true"
      :model="flashParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>
        <el-form-item label="活动名称" prop="name" label-width="150px">
          <el-input class="w-300" v-model.trim="flashParams.name" placeholder="请输入活动名称，2到16个文字组合" clearable maxlength="16"></el-input>
          <div class="color-info font-10 lh1 mt-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
        </el-form-item>
        <el-form-item label="活动时间" prop="startTime" label-width="150px">
          <el-date-picker
            class="w-500"
            :disabled="IS_EDIT"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([flashParams.startTime, flashParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
        <el-form-item label="活动预热时间" prop="preheatTime" label-width="150px">
          <div class="df aic wsn">
            <div>活动开始前</div>
            <div class="ml-10 mr-10">
              <el-input
                class="w-200"
                :value="flashParams.preheatTime"
                placeholder="请输入活动预热时间"
                maxLength="6"
                @input="(value) => flashParams.preheatTime = $moe_formatter.formatterInteger(value)">
              </el-input>
            </div>
            <div>小时开始预热</div>
            <el-tooltip class="ml-10" content="预热期无法享受秒杀优惠，将在商品详情页和秒杀活动页展示活动价格及活动时间" placement="top">
              <moe-icon name="reminder" size="20px"></moe-icon>
            </el-tooltip>
          </div>
        </el-form-item>
      </div>

      <div class="fwb mb-20 font-20">活动商品</div>
      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :data="flashParams.configList"
          :numberShow="false"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加活动商品"
          rowKey="goodsId">
          <el-form-item prop="configList" slot="tool">
            <div class="df aic">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
              <div class="df aic ml-10">已选<div class="color-primary">{{ `${flashParams.configList.length}个` }}</div>商品</div>
            </div>
          </el-form-item>

          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" prop="salePrice" min-width="150" :formatter="formatterSalePrice" />
          <el-table-column label="库存" prop="stock" width="80" />

          <el-table-column label="秒杀价" min-width="300">
            <div slot-scope="{ row, $index }" class="df aic jcc mt-20">
              <el-form-item :prop="`configList.${$index}.goodsItemList`" :rules="rules.goodsItemList">
                <el-button
                  v-if="row.goodsItemList.length"
                  @click="req_queryShopGoodsById(row, $index)"
                  icon="el-icon-circle-check"
                  type="text">
                  已设置
                </el-button>
                <el-button
                  v-else
                  @click="req_queryShopGoodsById(row, $index)"
                  icon="el-icon-thumb"
                  type="text">
                  设置秒杀优惠
                </el-button>
              </el-form-item>
            </div>
          </el-table-column>

          <el-table-column label="操作" min-width="350" fixed="right">
            <div class="moe-table__btns df aic jcc" slot-scope="{ row, $index }">
              <template v-if="$index > 0">
                <el-button type="success" size="mini" icon="el-icon-upload2" @click="handleClick('topping', row, $index)">置顶</el-button>
                <el-button type="primary" size="mini" icon="el-icon-top" @click="handleClick('top', row, $index)">上移</el-button>
              </template>
              <el-button v-if="$index < (flashParams.configList.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleClick('bottom', row, $index)">下移</el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleClick('delete', row, $index)">移除</el-button>
            </div>
          </el-table-column>
        </moe-table>
      </div>

      <el-button slot="tool" type="primary" icon="el-icon-upload2" :loading="flashLoad" @click="handleSubmit()">{{ flashLoad ? '保存中' : '保存' }}</el-button>
    </moe-form>

    <goodsDialog
      :showActivityRepel="true"
      :showDialog.sync="showDialog"
      :defaultSelectIds="flashParams.configList && flashParams.configList.length ? flashParams.configList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>

    <moe-dialog :show="form.showDialog" title="设置秒杀优惠" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-form
          v-if="form.showDialog"
          ref="activityGoodsItemForm"
          class="default-form"
          :showback="false"
          :showTool="false"
          :model="form"
          :rules="rules">
          <div style="height: 500px;min-height: 500px;" class="df fdc">
            <moe-table
              :numberShow="false"
              :data="form.list"
              :mode="false">
              <el-table-column label="商品信息" min-width="200">
                <div class="df aic" slot-scope="{ row }">
                  <moe-image :src="row.coverUrl" width="80px" height="80px" />
                  <div class="ml-10 df fdc aifs">
                    <p class="fwb tal">{{ row.goodsName }}</p>
                    <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                  </div>
                </div>
              </el-table-column>
              <el-table-column label="规格" min-width="200">
                <template slot-scope="{ row }">
                  <div class="df aic jcc">
                    <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="销售价" prop="salePrice" min-width="150">
                <template slot-scope="{ row }">
                  {{ `¥ ${row.salePrice}` }}
                </template>
              </el-table-column>
              <el-table-column label="库存" prop="allStock" min-width="80" />
              <el-table-column label="活动库存" min-width="200">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`list.${$index}.stock`" :rules="rules.stock">
                    <el-input
                      :value="row.stock"
                      @input="(value) => changeStock(value, $index)"
                      placeholder="请输入活动限购"
                      maxlength="9"
                      clearable />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="秒杀一口价" min-width="200">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`list.${$index}.price`" :rules="rules.price">
                    <el-input
                      :value="row.price"
                      @input="(value) => changePrice(value, $index)"
                      placeholder="请输入秒杀价格"
                      maxlength="9"
                      clearable />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="活动限购" min-width="200">
                <template slot-scope="{ row, $index }">
                  <el-form-item class="mt-20" :prop="`list.${$index}.quota`" :rules="rules.quota">
                    <el-input
                      :value="row.quota"
                      @input="(value) => row.quota = $moe_formatter.formatterInteger(value)"
                      placeholder="请输入活动限购"
                      maxlength="9"
                      clearable />
                  </el-form-item>
                </template>
              </el-table-column>
            </moe-table>
          </div>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageFlashAdd',
  components: {
    goodsDialog
  },
  computed: {
    IS_EDIT() {
      return this.flashParams.id ? true : false;
    },
  },
  data() {
    const checkPrick = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入秒杀价格'))
      } else {
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`秒杀价格必须大于0元`));
        }
        callback();
      }
    }
    const checkQuota = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入活动限购'));
      } else {
        let index = rule.fullField.split('.')[1];
        let findItem = this.form.list[index];
        if (findItem && this.$moe_math.mathGreaterThan(findItem.quota, findItem.stock)) {
          return callback(new Error('活动限购不能大于活动库存'));
        }
        callback();
      }
    }
    const verify = this.$moe_verify.verifyForm;
    return {
      flashParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        preheatTime: '',
        configList: [],
      },
      datetime: [],
      rules: {
        name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 20位'], '2~20'),
        startTime: verify.isEmpty('请选择活动时间'),
        preheatTime: verify.isEmpty('请输入活动预热时间'),
        configList: verify.isEmpty('请添加活动商品'),
        goodsItemList: verify.isEmpty('请设置秒杀优惠'),
        stock: verify.isEmpty('请输入活动库存'),
        // price: verify.isEmpty('请输入秒杀价格'),
        price: [{ required: true, validator: checkPrick, trigger: ['blur', 'change'] }],
        quota: [{ required: true, validator: checkQuota, trigger: ['blur', 'change'] }],
      },
      showDialog: false,
      form: {
        showDialog: false,
        goodsId: '',
        list: [],
      },
      flashLoad: false,
    }
  },
  methods: {
    /** 添加商品确认 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.flashParams.configList.find(({ goodsId }) => item.id === goodsId);
        if (!findItem) {
          this.flashParams.configList.push({
            ...item,
            goodsId: item.id,
            id: '',
            goodsItemList: [],
          })
        }
      })
      this.showDialog = false;
    },
    formatterSalePrice({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice)?` - ¥ ${maxPrice}`:''}`
    },
    changeStock(value, index) {
      let row = this.form.list[index];
      row.stock = this.$moe_formatter.formatterStock(value);
      try {
        if (this.$moe_math.mathGreaterThan(row.stock, row.allStock)) {
          row.stock = row.allStock;
        }
        this.$refs['activityGoodsItemForm'].validateField(`list.${index}.quota`);
      } catch {
        console.log('error')
      }
    },
    changePrice(value, index) {
      let row = this.form.list[index];
      row.price = this.$moe_formatter.formatterMoney(value);
      try {
        if (this.$moe_math.mathGreaterThan(row.price, row.salePrice)) {
          row.price = row.salePrice;
        }
      } catch {
        console.log('error')
      }
    },
    handleClick(type, { name }, index) {
      let { configList } = this.flashParams
      switch (type) {
        case 'delete':
          this.$moe_layer.confirm(`您确定要移除当前选项"${name}"吗？`, () => {
            configList.splice(index, 1);
          });
          break;
        case 'topping':
          this.$moe_layer.confirm(`您确定要置顶当前选项"${name}"吗？`, () => {
            const row = this.$moe_lodash.cloneDeepData(configList[index]);
            configList.splice(index, 1);
            configList.unshift(row);
            this.$moe_msg.success('置顶成功,提交后更新');
          });
          break;
        case 'top':
          this.$moe_layer.confirm(`您确定要上移当前选项"${name}"吗？`, () => {
            configList.splice(index - 1, 2, configList[index], configList[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':
          this.$moe_layer.confirm(`您确定要下移当前选项"${name}"吗？`, () => {
            configList.splice(index, 2, configList[index + 1], configList[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
      }
    },
    /** 设置秒杀优惠关闭 */
    handleDialogClose() {
      try {
        this.$refs['activityGoodsItemForm'].clearValidate();
      } catch {
        console.log('error');
      }
      this.form.showDialog = false;
    },
    /** 设置秒杀优惠确定 */
    handleConfirm() {
      this.$refs['activityGoodsItemForm'].validate(() => {
        let findIndex = this.flashParams.configList.findIndex(({ goodsId }) => this.form.goodsId === goodsId);
        this.flashParams.configList[findIndex].goodsItemList = this.$moe_lodash.cloneDeepData(this.form.list);
        try {
          this.$refs['flashForm'].validateField(`configList.${findIndex}.goodsItemList`);
        } catch {
          console.log('error');
        }
        this.handleDialogClose();
      })
    },
    /** 提交 */
    handleSubmit() {
      this.$refs['flashForm'].validate(() => {
        this.flashLoad = true;
        let configList = this.flashParams.configList.map(({ goodsItemList }) => {
          return goodsItemList.map(({ goodsId, goodsItemId, price, stock, quota }) => {
            return {
              goodsId,
              goodsItemId,
              price,
              stock,
              quota,
            }
          })
        }).flat();
        let params = {
          ...this.flashParams,
          configList,
        }
        if (this.IS_EDIT) {
          this.req_updateFlash(params);
        } else {
          this.req_addFlash(params);
        }
      })
    },
    /** 创建秒杀活动 */
    async req_addFlash(params) {
      const { code, message } = await this.$moe_api.FLASH_API.addFlash(params);
      this.flashLoad = false;
      if (code === 200) {
        this.$moe_msg.success('新建成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 修改秒杀活动 */
    async req_updateFlash(params) {
      const { code, message } = await this.$moe_api.FLASH_API.updateFlash(params);
      this.flashLoad = false;
      if (code === 200) {
        this.$moe_msg.success('编辑成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 获取商品详情 */
    async req_queryShopGoodsById(row, index) {
      this.form.goodsId = row.goodsId;
      const { code, result, message } = await this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.goodsId });
      if (code === 200) {
        let r = result;
        let goodsItemList = this.$moe_lodash.cloneDeepData(r.goodsItem);
        if (this.flashParams.configList[index].goodsItemList.length) {
          this.form.list = this.$moe_lodash.cloneDeepData(this.flashParams.configList[index].goodsItemList.map((item) => {
            let findItem = goodsItemList.find(({ id }) => item.goodsItemId === id);
            return {
              ...findItem,
              ...item,
              allStock: findItem.stock,
              goodsId: r.id,
              goodsName: r.name,
            }
          }));
        } else {
          this.form.list = goodsItemList.filter(({ delFlag }) => delFlag === 0).map((item) => {
            return {
              ...item,
              goodsItemId: item.id,
              id: '',
              goodsId: r.id,
              goodsName: r.name,
              allStock: item.stock,
              stock: '',
              price: '',
              quota: '',
            }
          })
        }

        this.form.showDialog = true;
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 获取秒杀活动详情 */
    async req_getFlashDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.FLASH_API.getFlashDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { id, name, startTime, endTime, preheatTime, configList } = result;
          this.flashParams = {
            id,
            name,
            startTime,
            endTime,
            preheatTime,
            configList: []
          }
          this.datetime = [startTime, endTime];
          if (configList.length) {
            this.req_getQueryShopGoodsByIds(configList);
          }
        } else {
          this.$moe_msg.error(message);
        }
      }
    },
    /** 根据商品ID获取商品列表 */
    async req_getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId');
      const { code, result, message } = await this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') });
      if (code === 200) {
        this.flashParams.configList = unionByList.map((item) => {
          let findGoodsItem = result.find(({ id }) => item.goodsId === id);
          let goodsItemList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId);
          //   .map((goodsListItem) => {
          //   return {
          //     ...goodsListItem,
          //   }
          // })
          return {
            ...findGoodsItem,
            goodsId: findGoodsItem.id,
            ...item,
            goodsItemList,
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    }
  },
  mounted() {
    this.req_getFlashDetail();
  }
}
</script>